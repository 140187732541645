<template>
  <v-card elevation="0" class="pa-0 my-2">
    <table>
      <tr>
        <td><label>User ID</label></td>
        <td>{{ user._id }}</td>
        <td><label>Created Date</label></td>
        <td>{{ friendlyDate(user.createdOn) }}</td>
      </tr>
      <tr>
        <td><label>Name</label></td>
        <td>{{ user.title }} {{ user.firstname }} {{ user.lastname }}</td>
        <td><label>Role</label></td>
        <td>{{ user.role }}</td>
      </tr>
      <tr>
        <td><label>Email</label></td>
        <td>{{ user.username }}</td>
        <td><label>Phone</label></td>
        <td>{{ user.phone }}</td>
      </tr>
      <tr>
        <td><label>Secondary Email</label></td>
        <td>{{ user.ccemail }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td><label>Nationality</label></td>
        <td>{{ user.nationality }}</td>
        <td><label>Source</label></td>
        <td>{{ user.source }}</td>
      </tr>
      <tr>
        <td><label>Date of Birth</label></td>
        <td>{{ user.dateofbirth }}</td>
        <td><label>Credit Card</label></td>
        <td>{{ user.omiseLast4 }}</td>
      </tr>

      <tr>
        <td><label>Year to Date Spending</label></td>
        <td>
          {{ compYTDSpending }} ( {{ calcNextTierAmount(compYTDSpending) }} )
        </td>
        <td><label>Tier</label></td>
        <td>{{ compYTDSpendingTier }}</td>
      </tr>

      <tr>
        <td><label>Credit Balance</label></td>
        <td>{{ user.credit }}</td>
        <td><label>User Type</label></td>
        <td>{{ user.type }}</td>
      </tr>

      <tr>
        <td><label>Tax Code</label></td>
        <td>{{ user.taxCode }}</td>
        <td><label>National ID</label></td>
        <td>{{ user.nationalID }}</td>
      </tr>

      <tr>
        <td><label>Name on ID/Company Name</label></td>
        <td>{{ user.companyAddress1 }}</td>
        <td><label>Branch</label></td>
        <td>{{ user.companyBranch }}</td>
      </tr>
      <tr>
        <td><label>Phone</label></td>
        <td>{{ user.companyPhone }}</td>
        <td><label>Email</label></td>
        <td>{{ user.companyEmail }}</td>
      </tr>
      <tr>
        <td><label>District</label></td>
        <td>{{ user.companyDistrict }}</td>
        <td><label>Subdistrict</label></td>
        <td>{{ user.companySubDistrict }}</td>
      </tr>
      <tr>
        <td><label>Province</label></td>
        <td>{{ user.companyProvince }}</td>
        <td><label>Country</label></td>
        <td>{{ user.companyCountry }}</td>
      </tr>
      <tr>
        <td><label>Postcode</label></td>
        <td>{{ user.companyPostcode }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td colspan="3">
          <label for="">Bookings</label> <br />
          <table>
            <tr>
              <td><label>Booking No.</label></td>
              <td><label>Created</label></td>
              <td><label>Status</label></td>
            </tr>
            <tr v-for="booking in compBookings" item-key="_id">
              <td>
                <router-link v-bind:to="'/Bookings/' + booking.confirmation">{{
                  booking.confirmation
                }}</router-link>
              </td>
              <td>{{ friendlyDate(booking.createdOn) }}</td>
              <td>{{ booking.status }}</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </v-card>
</template>
<script>
const moment = require("moment");
import { mapGetters } from "vuex";
import helpers from "@/plugins/helpers";
import VueJsonToCsv from "vue-json-to-csv";
export default {
  name: "user-details-card",
  data: () => ({
    customerSpendingJSON: null
  }),
  props: {
    user: Object
  },
  components: {
    VueJsonToCsv
  },
  methods: {
    hideEmpty(d) {
      // console.log(d)
      // console.log(typeof(d))

      // if(typeof(d)!=='undefined') return d
      if (typeof d === "boolean") return String(d);
    },
    formatThaiTime(d) {
      return moment
        .utc(d)
        .local()
        .format("DD MMM YYYY HH:mm:ss (Z)");
    },
    friendlyDate(d) {
      return helpers.formatActivityDateTime(d);
    },
    isTransactionWithinLastYear(transaction) {
      let today = new moment();
      let lastYear = today.subtract(1, "year").startOf("day");
      if (
        moment(transaction.dateCreated)
          .utcOffset("+07:00")
          .startOf("day")
          .isSameOrAfter(lastYear)
      )
        return true;
    },
    calcNextTierAmount(ytd) {
      if (ytd) {
        if (Number(ytd) < 75000) return 75000;
        if (Number(ytd) >= 75000 && Number(ytd) < 150000) return 150000;
        if (Number(ytd) >= 150000 && Number(ytd) < 500000) return 500000;
      }
      return 0;
    }
    // isTransactionAmount(transaction) {
    //   console.table(transaction.grandTotal)
    //   return true;

    //   // console.log(`gt: ${transaction.grandTotal}`)
    //   // if (transaction.grandTotal > 0) return true;
    // }
  },
  computed: {
    ...mapGetters(["bookingsAndCustomers"]),
    compBookings() {
      return this.bookingsAndCustomers.filter(
        b => b.customerID === this.user._id
      );
    },
    compYTDSpending() {
      // loop customer bookings, and if transaction createdOn is within the past year, add it to temporary array
      // let transactions = []
      // var lastYearsTransactions = []
      let bookings = this.compBookings;

      // create array of transactions
      let transactions = bookings.flatMap(obj => obj.transactions);

      // remove payment link transactions
      transactions = transactions.filter(
        t => t.type !== "Payment Link Purchase"
      );

      // filter transactions so they fall within the previous 12 months
      let last12MonthsTransactions = transactions.filter(t =>
        helpers.isTransactionWithinPrevious12Months(t)
      ); 

      // convert refund and bank transfer transactions to negative values;
      last12MonthsTransactions.forEach(t => {
        if (t.type === "Refund" || t.type === "Refund Bank Transfer") {
          t.grandTotal = -Math.abs(t.grandTotal);
        }
      });

      // Use reduce to calculate the total of the "value" property
      let totalValue = last12MonthsTransactions.reduce(function(
        accumulator,
        currentValue
      ) {
        let grandTotal = currentValue.grandTotal
          ? Number(currentValue.grandTotal)
          : 0;
        return accumulator + grandTotal;
      },
      0);

      // get the total of the users payment link purchases where the service is 'storage'
      const totalPaymentLinkPurchasesInLast12Months = this
        .compTotalPaymentLinkPurchasesInLast12Months;

      // add the total of the payment link purchases to the totalValue
      totalValue += totalPaymentLinkPurchasesInLast12Months;

      const total = Math.ceil(totalValue);

      return total;
    },
    compYTDSpendingTier() {
      return helpers.compYTDSpendingTier(this.compYTDSpending);
    },
    compTotalPaymentLinkPurchasesInLast12Months() {
      const userID = this.user._id;
      let paymentLinks = this.$store.state.paymentLinks.filter(
        pl => pl.customerID === userID
      );
      let paymentLinkPurchases = paymentLinks.filter(pl => pl.charge !== null);

      // filter out payment link purchases that are not within the past 12 months
      let last12MonthsPaymentLinkPurchases = paymentLinkPurchases.filter(pl =>
        helpers.isPaymentLinkPurchaseWithinPrevious12Months(pl)
      );

      // use reduce to calculate the total of all line items which have a service of 'Storage service'
      let totalValue = 0;
      last12MonthsPaymentLinkPurchases.forEach(pl => {
        pl.lines.forEach(li => {
          if (li.service === "Storage service") {
            totalValue += Number(li.price) * Number(li.quantity);
          }
        });
      });

      const total = Math.ceil(totalValue);

      return total;
    }
    //      if(this.compYTDSpending) {
    //        if(Number(this.compYTDSpending)>=75000 && Number(this.compYTDSpending) < 150000) return 'TIER 1';
    //        if(Number(this.compYTDSpending)>=150000 && Number(this.compYTDSpending) < 500000) return 'TIER 2';
    //        if(Number(this.compYTDSpending)>=500000 ) return 'TIER 3';
    //      }
    //      return ''
    //    }
  }
};
</script>
<style scoped>
table {
  width: 100%;
  /*		margin-bottom: 20px;*/
}

table label {
  font-weight: bold;
}

table tr td:nth-child(1) {
  width: 20%;
}

table tr td:nth-child(2) {
  width: 30%;
}

table tr td:nth-child(3) {
  width: 20%;
}

table tr td:nth-child(4) {
  width: 30%;
}
</style>
