<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-12">
          <v-card-title>USER MANAGEMENT</v-card-title>
          <vue-json-to-csv
            v-if="role === 'Admin'"
            :json-data="compUserData"
            :csv-title="'users'"
          >
            <v-btn
              color="primary"
              outlined
              class="mb-2"
              style="position: absolute; top: 10px; right: 150px;"
            >
              Download
            </v-btn>
          </vue-json-to-csv>
          <v-dialog v-model="dialog" max-width="800px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                style="position: absolute; top: 10px; right: 10px;"
              >
                New User
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary white--text">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="editedItem.username"
                          label="Username (email)"
                          :rules="[rules.email]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="editedItem.ccemail"
                          label="Secondary email"
                          :rules="[rules.email]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          v-if="editedItem.role === 'customer'"
                          v-model="editedItem.credit"
                          label="Credit"
                          :rules="[v => /^\d+$/.test(v) || 'Must be a number']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-switch
                          v-model="editedItem.enabled"
                          label="Enabled?"
                        ></v-switch>
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="editedItem.password" label="Password" type="password"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="editedItem.confirmPassword" label="Confirm Password" type="password"></v-text-field>
                        </v-col> -->
                      <v-col cols="12" sm="6" md="2">
                        <v-select
                          v-model="editedItem.title"
                          :items="titles"
                          label="Title"
                          :rules="[v => !!v || 'Title is required']"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="5">
                        <v-text-field
                          v-model="editedItem.firstname"
                          label="First Name"
                          :rules="[v => !!v || 'First Name is required']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="5">
                        <v-text-field
                          v-model="editedItem.lastname"
                          label="Last Name"
                          :rules="[v => !!v || 'Last Name is required']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="editedItem.role"
                          :items="roles"
                          label="Role"
                          :rules="[v => !!v || 'Role is required']"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.phone"
                          label="Phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <div>
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.dateofbirth"
                                label="Date of Birth"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.dateofbirth"
                              :active-picker.sync="activePicker"
                              :max="
                                new Date(
                                  Date.now() -
                                    new Date().getTimezoneOffset() * 60000
                                )
                                  .toISOString()
                                  .substr(0, 10)
                              "
                              min="1950-01-01"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="editedItem.nationality"
                          :items="nationalities"
                          label="Nationality"
                          :rules="[v => !!v || 'Nationality is required']"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="editedItem.note" label="Notes">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="editedItem.type"
                          :items="userTypes"
                          label="Type"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="editedItem.companyName"
                          label="Company Name"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <v-text-field
                          v-model="editedItem.taxCode"
                          label="Tax Code"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="editedItem.companyBranch"
                          label="Branch"
                          :rules="[v => /^\d+$/.test(v) || 'Must be a number']"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.companyEmail"
                          label="Email Address"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.companyPhone"
                          label="Phone Number"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="editedItem.companyAddress1"
                          label="Address"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-if="editedItem.companyCountry === 'Thailand'"
                          v-model="editedItem.companyProvince"
                          :items="compProvinceOptions"
                          label="Province"
                        >
                        </v-select>
                        <v-text-field
                          v-else
                          v-model="editedItem.companyProvince"
                          label="Province"
                        >
                        </v-text-field>
                        <!-- <v-select v-model="editedItem.companyProvince" :items="compProvinceOptions" label="Province">
                                                </v-select> -->
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-if="editedItem.companyCountry === 'Thailand'"
                          v-model="editedItem.companyDistrict"
                          :items="districtOptions"
                          :disabled="!districtOptions.length"
                          label="District"
                        >
                        </v-select>
                        <v-text-field
                          v-else
                          v-model="editedItem.companyDistrict"
                          label="District"
                        >
                        </v-text-field>
                        <!-- <v-select v-model="editedItem.companyDistrict" :items="districtOptions" :disabled='!districtOptions.length' label="District">
                                                </v-select> -->
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-if="editedItem.companyCountry === 'Thailand'"
                          v-model="editedItem.companySubDistrict"
                          :items="subDistrictOptions"
                          :disabled="!subDistrictOptions.length"
                          label="Sub District"
                        >
                        </v-select>
                        <v-text-field
                          v-else
                          v-model="editedItem.companySubDistrict"
                          label="Sub District"
                        >
                        </v-text-field>
                        <!-- <v-select v-model="editedItem.companySubDistrict" :items="subDistrictOptions" :disabled='!subDistrictOptions.length' label="Sub District">
                                              </v-select> -->
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                          v-model="editedItem.companyCountry"
                          :items="nationalities"
                          persistent-hint
                          label="Country"
                        >
                        </v-autocomplete>
                        <!-- <v-text-field v-model="editedItem.companyCountry" label="Country">
                                                </v-text-field> -->
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-if="editedItem.companyCountry === 'Thailand'"
                          v-model="editedItem.companyPostcode"
                          :items="postCodeOptions"
                          :disabled="!postCodeOptions.length"
                          label="Postcode"
                        >
                        </v-select>
                        <v-text-field
                          v-else
                          v-model="editedItem.companyPostcode"
                          label="Postcode"
                        >
                        </v-text-field>

                        <!-- <v-select v-model="editedItem.companyPostcode" :items="postCodeOptions" :disabled='!postCodeOptions.length' label="Postcode">
                                              </v-select> -->
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.omiseId"
                          label="Omise Token"
                          readonly
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-card-subtitle
            >Add/Edit User Accounts
            <v-btn
              v-if="customerID"
              text
              small
              color="primary"
              @click="showAllUsers()"
              >Show All</v-btn
            >
          </v-card-subtitle>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="headers"
                    :items="compUsers"
                    class="elevation-0"
                    disable-pagination
                    :hide-default-footer="true"
                    :search="search"
                    :expanded.sync="expanded"
                    item-key="_id"
                    show-expand
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-select
                              v-model="roleFilter"
                              :items="roles"
                              label="Role"
                              single-line
                              hide-details
                              clearable
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-switch
                              v-model="showDisabled"
                              label="Show Disabled?"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.enabled="{ item }">
                      <v-icon v-if="item.enabled" small>mdi-check-bold</v-icon>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class=" " @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <template v-slot:item.note="{ item }">
                      <v-tooltip v-if="item.note" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-comment-text
                          </v-icon>
                        </template>
                        <span>{{ item.note }}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <UserDetailsCard :user="item" />
                      </td>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import helpers from "@/plugins/helpers";
import UserDetailsCard from "@/components/userDetailsCard.vue";
import VueJsonToCsv from "vue-json-to-csv";
import { mapGetters } from "vuex";
const axios = require("axios");
axios.defaults.withCredentials = true;
import moment from "moment";
export default {
  name: "Users",
  components: {
    UserDetailsCard,
    VueJsonToCsv
  },
  data: () => ({
    rules: {
      required: value => !!value || "Required.",
      email: value => {
        if (!value) return true;
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    },
    customerID: null,
    activePicker: null,
    date: null,
    menu: false,
    valid: true,
    search: "",
    roleFilter: "",
    expanded: [],
    showDisabled: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Username",
        align: "start",
        value: "username"
      },
      { text: "ID", value: "_id" },
      { text: "Enabled?", value: "enabled" },
      { text: "First Name", value: "firstname" },
      { text: "Last Name", value: "lastname" },
      { text: "Role", value: "role" },
      { text: "Actions", value: "actions", sortable: false },
      { text: "Notes", value: "note" },
      { text: "", value: "data-table-expand" }
    ],
    editedIndex: -1,
    editedItem: {
      username: "",
      ccemail: "",
      enabled: "",
      firstname: "",
      lastname: "",
      role: "",
      phone: "",
      password: "",
      note: "",
      dateofbirth: "",
      credit: 0,
      companyProvince: "",
      companyDistrict: "",
      companySubDistrict: "",
      companyPostcode: "",
      type: ""
    },
    defaultItem: {
      username: "",
      ccemail: "",
      enabled: "",
      firstname: "",
      lastname: "",
      role: "",
      phone: "",
      password: "",
      note: "",
      dateofbirth: "",
      credit: 0,
      companyProvince: "",
      companyDistrict: "",
      companySubDistrict: "",
      companyPostcode: "",
      type: ""
    },
    userTypes: ["Personal", "Corporate"],
    titles: ["Mr.", "Mrs.", "Ms.", "Other"],
    roles: ["customer", "Admin", "Head", "Chief", "Chief In training"],
    nationalities: [
      "Afghanistan",
      "Åland Islands",
      "Albania",
      "Algeria",
      "American Samoa",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antarctica",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia, Plurinational State of",
      "Bonaire, Sint Eustatius and Saba",
      "Bosnia and Herzegovina",
      "Botswana",
      "Bouvet Island",
      "Brazil",
      "British Indian Ocean Territory",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cape Verde",
      "Cayman Islands",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Christmas Island",
      "Cocos (Keeling) Islands",
      "Colombia",
      "Comoros",
      "Congo",
      "Congo, the Democratic Republic of the",
      "Cook Islands",
      "Costa Rica",
      "Côte d'Ivoire",
      "Croatia",
      "Cuba",
      "Curaçao",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Ethiopia",
      "Falkland Islands (Malvinas)",
      "Faroe Islands",
      "Fiji",
      "Finland",
      "France",
      "French Guiana",
      "French Polynesia",
      "French Southern Territories",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Heard Island and McDonald Islands",
      "Holy See (Vatican City State)",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran, Islamic Republic of",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea, Democratic People's Republic of",
      "Korea, Republic of",
      "Kuwait",
      "Kyrgyzstan",
      "Lao People's Democratic Republic",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macao",
      "Macedonia, the former Yugoslav Republic of",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Martinique",
      "Mauritania",
      "Mauritius",
      "Mayotte",
      "Mexico",
      "Micronesia, Federated States of",
      "Moldova, Republic of",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Niue",
      "Norfolk Island",
      "Northern Mariana Islands",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestinian Territory, Occupied",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Pitcairn",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Réunion",
      "Romania",
      "Russian Federation",
      "Rwanda",
      "Saint Barthélemy",
      "Saint Helena, Ascension and Tristan da Cunha",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Martin (French part)",
      "Saint Pierre and Miquelon",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Sint Maarten (Dutch part)",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Georgia and the South Sandwich Islands",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Svalbard and Jan Mayen",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syrian Arab Republic",
      "Taiwan, Province of China",
      "Tajikistan",
      "Tanzania, United Republic of",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tokelau",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks and Caicos Islands",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "United States Minor Outlying Islands",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela, Bolivarian Republic of",
      "Viet Nam",
      "Virgin Islands, British",
      "Virgin Islands, U.S.",
      "Wallis and Futuna",
      "Western Sahara",
      "Yemen",
      "Zambia",
      "Zimbabwe"
    ],
    passwordRules: [
      v =>
        v.length >= 8 ||
        "Password must be 8 characters, and include at least one number",
      v =>
        this.checkPasswordComplexity(v) ||
        "Password must be 8 characters, and include at least one number"
    ],
    passwordConfirmationRules: [
      v => !!v || "Password is required",
      v => v == this.editedItem.password || "Password does not match"
    ],
    districtOptions: [],
    subDistrictOptions: [],
    postCodeOptions: []
  }),
  computed: {
    compLocale() {
      return "en";
    },
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    users() {
      return this.$store.state.users;
    },
    paymentLinks() {
      return this.$store.state.paymentLinks;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
    compUsers() {
      let users = this.users;

      // remove disabled?
      if (!this.showDisabled) {
        users = users.filter(u => u.enabled === true);
      }

      // if linked to from other page with customer id param:
      if (this.customerID) {
        users = users.filter(u => u._id === this.customerID);
      }

      // role based filter
      if (this.role === "Chief" || this.role === "Chief In training") {
        users = users.filter(u => u.role === "customer");
      }

      // role based filter
      if (this.role === "Head") {
        users = users.filter(u =>
          ["Chief", "Chief In training", "customer"].includes(u.role)
        );
      }

      // role based filter (via select at top of page)
      if (this.roleFilter) {
        users = users.filter(u => u.role === this.roleFilter);
      }

      return users;
    },
    compProvinceOptions() {
      return [
        "Amnat Charoen",
        "Ang Thong",
        "Bangkok",
        "Bueng Kan",
        "Buri Ram",
        "Chachoengsao",
        "Chai Nat",
        "Chaiyaphum",
        "Chanthaburi",
        "Chiang Mai",
        "Chiang Rai",
        "Chon Buri",
        "Chumphon",
        "Kalasin",
        "Kamphaeng Phet",
        "Kanchanaburi",
        "Khon Kaen",
        "Krabi",
        "Lampang",
        "Lamphun",
        "Loei",
        "Lop Buri",
        "Mae Hong Son",
        "Maha Sarakham",
        "Mukdahan",
        "Nakhon Nayok",
        "Nakhon Pathom",
        "Nakhon Phanom",
        "Nakhon Ratchasima",
        "Nakhon Sawan",
        "Nakhon Si Thammarat",
        "Nan",
        "Narathiwat",
        "Nong Bua Lam Phu",
        "Nong Khai",
        "Nonthaburi",
        "Pathum Thani",
        "Pattani",
        "Phangnga",
        "Phatthalung",
        "Phayao",
        "Phetchabun",
        "Phetchaburi",
        "Phichit",
        "Phitsanulok",
        "Phra Nakhon Si Ayutthaya",
        "Phrae",
        "Phuket",
        "Prachin Buri",
        "Prachuap Khiri Khan",
        "Ranong",
        "Ratchaburi",
        "Rayong",
        "Roi Et",
        "Sa Kaeo",
        "Sakon Nakhon",
        "Samut Prakan",
        "Samut Sakhon",
        "Samut Songkhram",
        "Saraburi",
        "Satun",
        "Si Sa Ket",
        "Sing Buri",
        "Songkhla",
        "Sukhothai",
        "Suphan Buri",
        "Surat Thani",
        "Surin",
        "Tak",
        "Trang",
        "Trat",
        "Ubon Ratchathani",
        "Udon Thani",
        "Uthai Thani",
        "Uttaradit",
        "Yala",
        "Yasothon",
        "กระบี่",
        "กรุงเทพมหานคร",
        "กาญจนบุรี",
        "กาฬสินธุ์",
        "กำแพงเพชร",
        "ขอนแก่น",
        "จันทบุรี",
        "ฉะเชิงเทรา",
        "ชลบุรี",
        "ชัยนาท",
        "ชัยภูมิ",
        "ชุมพร",
        "ตรัง",
        "ตราด",
        "ตาก",
        "นครนายก",
        "นครปฐม",
        "นครพนม",
        "นครราชสีมา",
        "นครศรีธรรมราช",
        "นครสวรรค์",
        "นนทบุรี",
        "นราธิวาส",
        "น่าน",
        "บึงกาฬ",
        "บุรีรัมย์",
        "ปทุมธานี",
        "ประจวบคีรีขันธ์",
        "ปราจีนบุรี",
        "ปัตตานี",
        "พระนครศรีอยุธยา",
        "พะเยา",
        "พังงา",
        "พัทลุง",
        "พิจิตร",
        "พิษณุโลก",
        "ภูเก็ต",
        "มหาสารคาม",
        "มุกดาหาร",
        "ยะลา",
        "ยโสธร",
        "ระนอง",
        "ระยอง",
        "ราชบุรี",
        "ร้อยเอ็ด",
        "ลพบุรี",
        "ลำปาง",
        "ลำพูน",
        "ศรีสะเกษ",
        "สกลนคร",
        "สงขลา",
        "สตูล",
        "สมุทรปราการ",
        "สมุทรสงคราม",
        "สมุทรสาคร",
        "สระบุรี",
        "สระแก้ว",
        "สิงห์บุรี",
        "สุพรรณบุรี",
        "สุราษฎร์ธานี",
        "สุรินทร์",
        "สุโขทัย",
        "หนองคาย",
        "หนองบัวลำภู",
        "อำนาจเจริญ",
        "อุดรธานี",
        "อุตรดิตถ์",
        "อุทัยธานี",
        "อุบลราชธานี",
        "อ่างทอง",
        "เชียงราย",
        "เชียงใหม่",
        "เพชรบุรี",
        "เพชรบูรณ์",
        "เลย",
        "แพร่",
        "แม่ฮ่องสอน"
      ];
    },
    compUserData() {
      // used in CSV download

      let users = this.compUsers;

      const userData = users.map(item => {
        return {
          username: item.username,
          title: item.title,
          firstName: item.firstname,
          lastName: item.lastname,
          role: item.role,
          credit: item.credit,
          activeBookings: this.hasActiveBookings(item._id),
          isSubscribed: this.hasActiveSubscription(item._id),
          type: this.formatBlank(item.type)
        };
      });

      return userData;
    },
    ...mapGetters(["bookingsAndCustomers"]),
    compActiveBookings() {
      let data = this.bookingsAndCustomers;
      // remove inactive bookings
      data = data.filter(d => d.status !== "Inactive");
      return data;
    },
    compSubscriptionBookings() {
      let data = this.compActiveBookings;
      // remove non-subscription bookings
      data = data.filter(d => d.isSubscription === true);
      return data;
    }
  },
  methods: {
    formatBlank(value) {
      if (value === "undefined") return "";
      if (typeof value === "undefined") return "";
      return value;
    },
    hasActiveBookings(id) {
      const booking = this.compActiveBookings.find(b => b.customerID === id);
      if (booking) {
        return true;
      }
      return false;
    },
    hasActiveSubscription(id) {
      const booking = this.compSubscriptionBookings.find(
        b => b.customerID === id
      );
      if (booking) {
        return true;
      }
      return false;
    },
    showAllUsers() {
      this.customerID = null;
    },
    checkPasswordComplexity: function(password) {
      var letters = 0;
      var digits = 0;
      var uppers = 0;
      var lowers = 0;
      var symbols = 0;
      var i = password.length;
      while (i--) {
        if (password.charAt(i).match(/[a-z]/i)) letters++;
        if (password.charAt(i).match(/[0-9]/i)) digits++;
      }
      if (letters < 1) return false;
      if (digits < 1) return false;
      return true;
    },
    // saveDOB(date) {
    //   this.$refs.menu.save(date);
    // },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.companyPostcode)
        this.editedItem.companyPostcode = Number(item.companyPostcode);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      let vm = this;
      let user = this.editedItem;
      // user.dateofbirth = this.date;
      let index = this.editedIndex;

      // DEV PERMISSIONS
      let editIsAllowed = true;
      const originalUser = vm.users.find(f => f._id === user._id);

      // if(originalUser) {
      //     console.log(`Existing role: ${originalUser.role}`)
      //     console.log(`NEW role: ${user.role}`)
      // }

      // console.log(this.role)
      // console.log(typeof(originalUser))
      // console.log(user.role)

      // NO ROLE MAY CREATE A CUSTOMER:
      // if (typeof(originalUser) === 'undefined' && user.role === 'customer') editIsAllowed = false;

      // HEAD role permissions:
      if (this.role === "Head") {
        if (typeof originalUser === "undefined" && user.role === "Admin")
          editIsAllowed = false;
        if (typeof originalUser === "undefined" && user.role === "Head")
          editIsAllowed = false;
        if (
          user.role !== "Chief" &&
          user.role !== "Chief In training" &&
          user.role !== "customer"
        )
          editIsAllowed = false;
      }

      // CHIEF role permissions:
      if (this.role === "Chief") {
        if (typeof originalUser === "undefined" && user.role === "Admin")
          editIsAllowed = false;
        if (typeof originalUser === "undefined" && user.role === "Head")
          editIsAllowed = false;
        if (typeof originalUser === "undefined" && user.role === "Chief")
          editIsAllowed = false;
        if (
          typeof originalUser === "undefined" &&
          user.role === "Chief In training"
        )
          editIsAllowed = false;
      }

      // CHIEF IN TRAINING role permissions:
      if (this.role === "Chief In training") {
        if (typeof originalUser === "undefined" && user.role === "Admin")
          editIsAllowed = false;
        if (typeof originalUser === "undefined" && user.role === "Head")
          editIsAllowed = false;
        if (typeof originalUser === "undefined" && user.role === "Chief")
          editIsAllowed = false;
        if (
          typeof originalUser === "undefined" &&
          user.role === "Chief In training"
        )
          editIsAllowed = false;
      }

      // NO ROLE MAY CHANGE A CUSTOMER ROLE INTO SOMETHING ELSE
      if (originalUser?.role === "customer" && user.role !== "customer")
        editIsAllowed = false;

      if (editIsAllowed) {
        // console.log("allowed");
        axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.token}`
        };
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            axios
              .put(vm.$root.urls.api + "/v3/user", {
                user
              })
              .then(function(results) {
                Object.assign(vm.users[index], user);
                // toast
                vm.$toasted.show("User updated").goAway(2500);
                vm.close();
              })
              .catch(function(error) {
                // toast
                vm.$toasted
                  .show(`Unable to update user: ${error.response.data}`)
                  .goAway(2500);
              });
          } else {
            // save new user via API
            axios
              .post(vm.$root.urls.api + "/v3/user", {
                user
              })
              .then(function(results) {
                // vm.users.push(user);
                console.log(results.data);
                vm.users.push(results.data);
                // toast
                vm.$toasted.show("User added").goAway(2500);
                vm.close();
              })
              .catch(function(error) {
                console.log(error);
                // toast
                vm.$toasted.show("Unable to add user").goAway(2500);
              });
          }
          // this.close();
        }
      } else {
        console.log("NOT allowed");
        vm.$toasted
          .show("Sorry, you do not have permission to make this edit.", {
            type: "error",
            icon: "mdi-alert-circle"
          })
          .goAway(2500);
      }
    },
    getDistricts() {
      let vm = this;
      if (vm.editedItem.companyProvince) {
        axios
          .get(vm.$root.urls.api + `/v3/districts/`, {
            params: {
              province: vm.editedItem.companyProvince,
              locale: vm.compLocale
            }
          })
          .then(function(response) {
            vm.districtOptions = response.data;

            if (vm.editedItem.companyDistrict) {
              vm.getSubDistricts();
            }
          });
      }
    },
    getSubDistricts() {
      let vm = this;
      if (vm.editedItem.companyDistrict) {
        axios
          .get(vm.$root.urls.api + `/v3/districts/`, {
            params: {
              province: vm.editedItem.companyProvince,
              district: vm.editedItem.companyDistrict,
              locale: vm.compLocale
            }
          })
          .then(function(response) {
            vm.subDistrictOptions = response.data;
            if (vm.editedItem.companySubDistrict) {
              vm.getPostalCode();
            }
          });
      }
    },
    getPostalCode() {
      let vm = this;
      if (vm.editedItem.companySubDistrict) {
        axios
          .get(vm.$root.urls.api + `/v3/districts/`, {
            params: {
              province: vm.editedItem.companyProvince,
              district: vm.editedItem.companyDistrict,
              subDistrict: vm.editedItem.companySubDistrict,
              locale: vm.compLocale
            }
          })
          .then(function(response) {
            vm.postCodeOptions = response.data;
          });
      }
    }
  },
  mounted: function() {
    // listen for unit id parameter
    if (this.$route.params.id) {
      this.customerID = this.$route.params.id;
      // this.expanded.push(this.compUnits[0])
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    editedItem: {
      handler: function(val, oldVal) {
        // console.log('editedItem', 'newval: ', val, '   oldVal:', oldVal);
        if (val.companyProvince) {
          this.getDistricts();
          this.districtOptions = [];
          this.subDistrictOptions = [];
          this.postCodeOptions = [];
        }

        if (val.companyDistrict) {
          this.getSubDistricts();
          this.subDistrictOptions = [];
          this.postCodeOptions = [];
        }

        if (val.companySubDistrict) {
          this.getPostalCode();
          this.postCodeOptions = [];
        }
      },
      deep: true
    }
  }
};
</script>
